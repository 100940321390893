import axios from 'axios';

const gtg = process.env.REACT_APP_BASE_URL;
const intialState = [];
const API = `https://${gtg}/packages`;
// const API = 'http://localhost:3000/packages';

const GET_PACKAGES = 'GET_PACKAGES';

const fetchPackages = () => (dipatch) => {
  axios.get(API).then((res) => {
    const packages = res.data;
    dipatch({
      type: GET_PACKAGES,
      packages,
    });
  });
};

// eslint-disable-next-line default-param-last
const packageReducer = (state = intialState, action) => {
  switch (action.type) {
    case GET_PACKAGES:
      return [
        ...action.packages,
      ];
    default:
      return state;
  }
};

export default packageReducer;
export { fetchPackages };
